<template>
  <div class="topImgWrap">
    <div class="iconWrap">
      <!-- <span v-if="showHref">图片:</span> -->
      <Upload
        ref="childUpload"
        :url.sync="item[imgkey]"
        :id.sync="item[idkey]"
        :cropper-options="cropperOptions"
      />
      <!-- <el-button
        v-if="showHref"
        class="selectImageBtn"
        type="info"
        @click="chooseIcon"
      >选择默认的图片</el-button
      > -->
    </div>
    <!-- <el-dialog :visible.sync="iconVisible" title="选择图片" custom-class="iconDialog" append-to-body>
      <div >
        <el-image
          v-for="item in iconList"
          :key="item.id"
          :src="item.iconUrl"
          :fit="'contain'"
          style="width:80px;height:80px;margin:10px 0;margin-right:10px;"
          lazy
          @click="chooseDefaultIcon(item.iconUrl)"
        />
      </div>
    </el-dialog> -->
    <slot />
    <!-- <div v-if="showHref" class="iconWrap">
      <span>链接:</span>
      <banner-href
        :item="item"
        :title-list="newsTitleList"
        :href-list="hrefList"
        @changeType="changeType"
      />
    </div> -->
  </div>
</template>

<script>
import Upload from "./uploadCropper";
// import BannerHref from './bannerhref'
// import { mapGetters } from 'vuex'
export default {
  components: {
    Upload,
    // BannerHref
  },
  inject: ["idkey"],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    imgkey: {
      type: String,
      default: "",
    },

    cropperOptions: {
      type: Object,
      default: () => {},
    },
    showHref: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      iconVisible: false,
      itemData: {},
    };
  },
  // computed: {
  //   ...mapGetters(['hrefList', 'newsTitleList'])
  // },
  methods: {
    changeType(e) {
      this.$set(e.item, [e.type], e.val);
    },
    chooseIcon() {
      this.iconVisible = true;
    },
    chooseDefaultIcon(url) {
      const index = this.cropperOptions.path.lastIndexOf("/");
      const str = this.cropperOptions.path.substring(index);
      const imgArr = url.split("style/");
      url = imgArr[0] + "style" + str;
      this.iconVisible = false;
      this.item[this.imgkey] = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.topImgWrap {
  .titleWrap,
  .iconWrap {
    display: flex;
    align-items: center;
    margin-top: 10px;
    span {
      min-width: 100px;
    }
  }
  .des {
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    font-weight: bold;
    color: #808080;
  }
  .selectImageBtn {
    margin-left: 25px;
  }
}
</style>
