<template>
  <el-dialog
    :visible="visible"
    :mask-closable="false"
    :confirm-loading="confirmLoading"
    :width="'800px'"
    :footer="null"
    :before-close="cancelHandel"
    title="裁剪图片"
    append-to-body
    style="min-width: 800px"
  >
    <div v-if="percentFlag" class="progressWrap">
      <el-progress :percentage="percentValue" type="circle" />
    </div>
    <el-row>
      <el-col :md="12" :style="{ width: '350px', height: '350px' }">
        <vue-cropper
          ref="cropper"
          :img="options.img"
          :info="true"
          :auto-crop="options.autoCrop"
          :output-size="options.outputSize"
          :fixed-number="options.fixedNumber"
          :auto-crop-width="options.autoCropWidth"
          :auto-crop-height="options.autoCropHeight"
          :fixed-box="options.fixedBox"
          :fixed="true"
          :can-move="options.canMove"
          :output-type="options.outputType"
          :full="options.full"
          @realTime="realTime"
        />
      </el-col>
      <el-col :md="11" :offset="1" :style="{ width: '350px', height: '350px' }">
        <div :style="previewStyle" class="avatar-upload-preview">
          <img :src="previews.url" :style="previews.img" />
        </div>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span="4">
        <el-upload
          :http-request="beforeUpload"
          :show-file-list="false"
          :accept="accept"
          name="file"
          action="https"
        >
          <el-button icon="upload">选择图片</el-button>
        </el-upload>
      </el-col>
      <el-col :span="2">
        <el-button icon="el-icon-plus" @click="changeScale(1)" />
      </el-col>
      <el-col :span="2">
        <el-button icon="el-icon-minus" @click="changeScale(-1)" />
      </el-col>
      <el-col :span="2">
        <el-button on icon="el-icon-refresh-left" @click="rotateLeft" />
      </el-col>
      <el-col :span="2">
        <el-button icon="el-icon-refresh-right" @click="rotateRight" />
      </el-col>
      <el-col :span="4" :offset="1">
        <el-button type="primary" @click="finish('blob')">裁剪上传</el-button>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="finish">原图上传</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import { convertBase64UrlToBlob } from "@/utils/index.js";
// import { mapGetters } from 'vuex'
export default {
  props: {
    uploadImg: {
      type: String,
      default: "",
    },
    cropperOptions: {
      type: Object,
      default: () => {},
    },
    accept: {
      type: String,
      default: "image/*",
    },
  },
  data() {
    const { fixedNumber } = this.cropperOptions || [1, 1];
    return {
      visible: false,
      id: null,
      confirmLoading: false,
      spinning: false,
      fileList: [],
      uploading: false,
      options: {
        // img: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        img: "",
        autoCrop: true,
        autoCropWidth: 750,
        autoCropHeight: 750,
        fixedBox: false,
        fixedNumber: fixedNumber,
        outputSize: 1,
        outputType: "png",
        canMove: false,
        full: true,
      },
      previews: {},
      previewStyle: {},
      uploadImgOptions: {},
      percentValue: 0,
      percentFlag: false,
    };
  },
  watch: {
    uploadImg(val) {
      if (val) {
        const image = new Image();
        this.options.img = val;
        image.onload = () => {
          this.edit();
        };
        image.src = val;
      }
    },
    // cropperOptions (val) {
    //   console.log(val)
    //   this.cropperOptions.fixedNumber = val.fixedNumber || [1, 1]
    //   Object.assign(this.uploadImgOptions, val)
    // }
  },
  // computed: {
  //   ...mapGetters(['programId'])
  // },
  destroyed() {
    this.$emit("cancel");
  },
  methods: {
    edit(id) {
      this.visible = true;
      this.id = id;
      this.uploadImgOptions.fixedNumber = this.cropperOptions.fixedNumber || [
        1,
        1,
      ];
      Object.assign(this.uploadImgOptions, this.cropperOptions);
      /* 获取原始头像 */
    },
    close() {
      this.id = null;
      this.visible = false;
      this.$emit("cancel");
    },
    cancelHandel() {
      this.close();
    },
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    beforeUpload(file) {
      const reader = new FileReader();
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file.file);
      reader.onload = () => {
        this.options.img = reader.result;
      };
      // 转化为blob
      // reader.readAsArrayBuffer(file)

      return false;
    },

    // 上传图片（点击上传按钮）
    finish(type) {
      console.log("finish");
      const _this = this;
      const formData = new FormData();
      // 输出
      if (type === "blob") {
        // 裁剪上传
        this.$refs.cropper.getCropBlob((data) => {
          formData.append(
            "file",
            data,
            `${new Date().getTime()}.${data.type.split("/")[1]}`
          );
          _this.upload(_this, formData);
        });
      } else {
        // 原图上传
        var urlFile = convertBase64UrlToBlob(
          this.uploadImg,
          new Date().getTime()
        );
        formData.append(
          "file",
          urlFile,
          `${new Date().getTime()}.${urlFile.type.split("/")[1]}`
        );
        _this.upload(_this, formData);
      }
    },
    okHandel() {
      const vm = this;

      vm.confirmLoading = true;
      setTimeout(() => {
        vm.confirmLoading = false;
        vm.close();
        vm.$message.success("上传头像成功");
      }, 2000);
    },

    realTime(data) {
      this.previewStyle = {
        width: data.w + "px",
        height: data.h + "px",
        overflow: "hidden",
        margin: "0 auto",
        zoom: data.w > data.h ? 300 / data.w : 300 / data.h,
        background: "#ccc",
      };
      this.previews = data;
    },
    upload(_this, formData) {
      const path = `${this.uploadImgOptions.path}`;
      this.$http
        .post(`/gov/uploadFile/${path}`, formData, {
          contentType: false,
          processData: false,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          onUploadProgress: function (progressEvent) {
            // 对原生进度事件的处理
            _this.percentFlag = true;
            _this.percentValue = parseInt(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        })
        .then((response) => {
          if (response.code === 0) {
            _this.$message.success("上传成功");
            setTimeout(() => {
              _this.percentFlag = false;
              _this.$emit("ok", {
                img: response.location,
                id: response.id,
              });
            }, 1000);
          } else {
            _this.$notification.error({ message: "上传失败,请重新上传" });
            _this.percentFlag = false;
            _this.close();
          }

          _this.visible = false;
        })
        .catch((err) => {
          console.log(err);
          _this.$notification.error({ message: "上传失败,请重新上传" });
          _this.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-upload-preview {
  width: 300px;
  height: 300px;
  // border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}
.progressWrap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  z-index: 10;
}
</style>
