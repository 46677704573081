<template>
  <div>
    <el-upload
      :show-file-list="false"
      :accept="accept"
      :http-request="beforeUpload"
      :action="action"
    >
      <el-image v-if="url" :src="url" :fit="'contain'" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon" />
    </el-upload>
    <Cropper
      ref="modal"
      :upload-img="uploadImg"
      :cropper-options="cropperOptions"
      :accept="accept"
      @ok="ok"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import Cropper from "./cropper.vue";
export default {
  components: {
    Cropper,
  },
  props: {
    accept: {
      type: String,
      default: "image/*",
    },
    name: {
      type: String,
      default: "",
    },
    cropperOptions: {
      type: Object,
      default: () => {},
    },
    url: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  inject: ["idkey"],
  data() {
    return {
      uploadImg: "",
      action: "https", // 必填参数随便填的
    };
  },
  methods: {
    beforeUpload(file) {
      const reader = new FileReader();
      if (file.file.size / 1024 / 1024 > 2) {
        this.$message.warning("图片大小不能超过2M");
        return;
      }
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file.file);
      reader.onload = () => {
        this.uploadImg = reader.result;
        // console.log(reader.result)
      };
      // 转化为blob
      // reader.readAsArrayBuffer(file)
      return false;
    },
    ok(obj) {
      this.$emit("update:url", obj.img);

      if (this.idkey) this.$emit("update:id", obj.id);
      this.uploadImg = "";
    },
    cancel() {
      this.uploadImg = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 148px;
  height: 148px;
  display: block;
  border: 1px solid #ccc;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  border: 1px solid #ccc;
}
</style>
