<template>
  <div class="detailContainer">
    <div class="titleCommon" style="margin-top: 40px">新闻设置</div>
    <el-form ref="newsForm" :model="newsForm" :rules="newsRules" @submit.native.prevent>
      <template>
        <p class="title">文章类型(自己编辑富文本或者微信公众号链接)</p>
        <div>
          <el-form-item class="elItem" prop="wechatUrl">
            <el-input v-model="wechartDetail.wechatUrl" class="input" type="textarea" placeholder="请复制微信公众号的文章链接" />
          </el-form-item>
          <p class="des">ps:无法解析带有视频的文章</p>
          <el-button :loading="newsLoading" :disabled="newsLoading" class="previewWechat" @click="analysis">解析</el-button>
        </div>
      </template>
      <div>
        <p class="title">
          新闻标题
          <span>（必填）</span>
        </p>
        <el-form-item class="elItem" prop="title">
          <el-input v-model="newsForm.title" class="input" />
        </el-form-item>
        <p class="title">
          添加封面图片
          <span>（必须添加并且图片大小不能超过2M）</span>
        </p>
        <el-button v-if="newsForm.remark" style="margin-top: 10px" @click="saveImg">导入解析后的封面图</el-button>
        <Topimg
          ref="upload"
          :item="newsForm"
          :imgkey="'imageUrl'"
          :cropper-options="{
            path: 'news/1/JpegQ75W700',
            fixedNumber: [1.74, 1]
          }"
          :show-href="false"
        >
          <p class="title titleSub">封面的图片大小为750*432像素</p>
        </Topimg>
        <p class="title">发布时间</p>
        <el-date-picker v-model="newsForm.createTime" type="datetime" placeholder="选择日期时间" style="margin-top: 10px" />

        <p class="title">
          "新闻正文"
          <span>（必填）</span>
        </p>

        <div style="padding-top: 20px">
          <tinymce v-model="newsForm.data" :height="700" />
        </div>
        <div class="btnWrap">
          <el-button :loading="newsLoading" round type="primary" @click="submit">保存上传</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import EventBus from '@/utils/eventBus'
import Tinymce from '@/components/Tinymce'
import { getNewsDetail, saveOneNews, getArticles } from '@/apis/user'
import { throttle, urlToBase64 } from '@/utils/index'
import Topimg from '@/components/upload425/index'
import { mapGetters } from 'vuex'
export default {
  components: { Tinymce, Topimg },
  provide: {
    idkey: 'imageId'
  },
  data() {
    const validateTitle = (rule, value, callback) => {
      // console.log(value)
      if (value.trim() === '') {
        callback(new Error('请输入新闻标题'))
      } else {
        callback()
      }
    }
    return {
      newsForm: {
        // 表单form
        title: '', // 标题
        data: '', // 新闻内容
        imageUrl: '', // 封面如偏
        imageId: '',
        createTime: '',
        wechatFlag: 0,
        remark: null
      },
      wechartDetail: {
        wechatUrl: '',
        image: ''
      },
      newsLoading: false, // 保存loading
      newsRules: {
        // 表单验证规则
        title: [
          {
            required: true,
            trigger: 'blur',
            validator: validateTitle
          }
        ]
      },
      flag: false // 解析成功后 点击确定按钮,改变标记，才让用户继续编辑
    }
  },
  computed: {
    ...mapGetters(['programInfo'])
  },
  mounted() {
    const { newsId } = this.$route.params
    if (newsId) {
      getNewsDetail({ newsId }).then(res => {
        this.newsForm = {
          ...res.result,
          createTime: new Date(res.result.createTime),
          wechatFlag: res.result.wechatFlag || 0
        }
      })
    }
  },
  methods: {
    submit() {
      this.$refs.newsForm.validate(valid => {
        // 表单验证
        if (valid) {
          if (this.newsForm.imageUrl === '') {
            this.$message1('请选择封面图片', 'warning')
            return
          }
          if (this.newsForm.data === '') {
            this.$message1('请填写新闻正文', 'warning')
            return
          }
          var message = ''
          if (this.newsForm.newsId) {
            message = '修改'
          } else {
            message = '新增'
          }

          this.$alertMsgBox('请确认是否要' + message + '这条新闻?')
            .then(() => {
              this.newsLoading = true
              this.newsForm.title = this.newsForm.title.trim()
              this.newsForm.createTime = new Date(this.newsForm.createTime).getTime()

              saveOneNews(this.newsForm)
                .then(() => {
                  if (this.newsForm.newsId) {
                    this.$message1('修改成功', 'success')
                  } else {
                    this.$message1('新增成功', 'success')
                    EventBus.$emit('needRefrush', true)
                  }

                  this.$router.back()
                  this.newsLoading = false
                })
                .catch(() => {
                  this.newsLoading = false
                })
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          if (this.newsForm.title.trim() === '') {
            this.$message1('请填写新闻标题', 'warning')
          }
        }
      })
    },

    // 预览防抖按钮
    analysis: throttle(function () {
      const { wechatUrl } = this.wechartDetail
      if (!wechatUrl) {
        this.$message1('请复制微信公众号链接', 'warning')
        return false
      }
      this.newsLoading = true
      getArticles({
        // programId: this.$route.params.programId,
        url: wechatUrl
      })
        .then(res => {
          this.$message1('解析成功', 'success')
          this.wechartDetail.image = res.result.image.imageOrigin

          this.newsForm.title = res.result.title
          this.newsForm.data = res.result.body
          this.newsForm.remark = wechatUrl
          this.newsForm.createTime = new Date(Number(res.result.datetime))
          this.newsForm.imageUrl = res.result.image.imageUrl
          this.newsForm.imageId = res.result.image.imageId
          this.wechartDetail.wechatUrl = ''
          this.newsLoading = false
        })
        .catch(() => {
          this.newsLoading = false
        })
    }, 1500),

    saveImg() {
      const { image } = this.wechartDetail
      if (!image) {
        this.$message1('这篇文章好像没有封面图', 'warning')
        return false
      }
      urlToBase64(image).then(res => {
        this.$refs.upload.$refs.childUpload.uploadImg = res
      })
    }
  }
}
</script>
<style lang="scss">
.detailContainer {
  .elItem {
    margin: 10px 0 0 0;
  }
  .input {
    max-width: 750px;
  }
  .newsSelect {
    margin-top: 10px;
    width: 360px;
  }
  .el-form {
    margin: 32px 60px 0 190px;
  }
  .el-form-item__content {
    display: flex;
  }
  .el-input {
    display: flex;
    align-items: center;
    input {
      -webkit-appearance: none;
      color: #333;
      caret-color: #333;
      border: 1px solid #e5e5e5;
      font-size: 16px;
      line-height: 20px;
      height: 40px;
    }
  }
  .textarea {
    margin-top: 18px;
  }
  .el-textarea__inner {
    max-width: 750px;
    border: solid 1px #cccccc;
    min-height: 112px !important;
  }
  .btnWrap {
    overflow: hidden;
    max-width: 750px;
    padding-bottom: 50px;
    margin-top: 40px;
  }
}
</style>

<style lang="scss" scoped>
.detailContainer {
  width: 1100px;
  margin: 0 auto;
  .titleCommon {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 34px;
  }
  .title {
    line-height: 1;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
    span {
      color: #808080;
    }
  }

  .titleSub {
    color: #b3b3b3;
    margin-top: 10px;
  }
  .previewWechat {
    margin-top: 10px;
  }
  .des {
    margin-top: 6px;
    color: #808080;
  }
}
</style>
